import axios from "axios";
import LeaveIcon from "./asset/icon/leave";
import WinerCard from "./winerCard";
import { ProjectApi } from "../api/Api";
import { useEffect, useState } from "react";
import {useStore} from "../store";


export default function WinerPage({modalHandler , countdown}){
    const [Data , setData]=useState([])
    const {player_id} = useStore()

    const winner =async()=>{
        try{
            const response = await axios.get(ProjectApi[0].winners,{
                headers:{
                    Authorization: player_id
                }
            });
            setData(response.data);
        }catch(err){
            console.log(err)
        }
    }

    useEffect(()=>{
        winner()
    },[player_id])
    return(
        <div className="fixed bg-black/85 w-[100vw] h-[100vh] top-0 overflow-y-scroll">
            <div className="max-w-[360px] w-[90%] mx-auto my-5 bg-[#696969] rounded-md border border-white">
                <div className="flex justify-between items-center px-3 my-4 ">
                    <p className="text-[#3bffff] text-sm font-extrabold">{countdown}</p>
                    <p onClick={modalHandler}><LeaveIcon /></p>
                </div>
                <div>
                    {
                        Data.map((data , index) =><WinerCard {...data} index={index+1}/>)
                    }
                
                </div>
            </div>
        </div>
    )
}