import axios from "axios";
import { ProjectApi } from "../api/Api";
import { useState } from "react";
import Swal from "sweetalert2";
import { useStore } from "../store";
import { useTonWallet } from "@tonconnect/ui-react";

export default function Button({ text, icon, className, values, controller }) {
  const wallet = useTonWallet();
  const [loading, setloadin] = useState(false);
  const { player_id } = useStore();
  const PostDicehandler = async () => {
    if (controller === "disable") {
      setloadin(true);
      if (!wallet?.account?.address) {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Please connect your wallet",
          draggable: true,
        });
      } else {
        Swal.fire({
          position: "center",
          icon: "error",
          title: "You must wait for the new event ",
          draggable: true,
        });
      }
    } else if (controller === "submit" || controller === "change") {
      try {
        setloadin(true);
        const response = await axios.post(
          ProjectApi[2].predict,
          {
            dice_number1: values.number1,
            dice_number2: values.number2,
          },
          {
            headers: {
              Authorization: player_id,
            },
          }
        );
        if (controller === "submit") {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Dice submitted successfully",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Dice change successfully",
            showConfirmButton: false,
            timer: 2000,
          });
        }
        setloadin(false);
      } catch (err) {
        console.log(err).fire({
          position: "center",
          icon: "error",
          title: "Try Again",
          draggable: true,
        });
      }
    }
  };

  return (
    <button
      disabled={loading}
      className={`${className} w-[140px] h-[43px] bg-black/10 rounded-[117px] border  px-[20px] flex justify-between items-center`}
      onClick={PostDicehandler}
    >
      <span>{icon}</span>
      <span>{text}</span>
    </button>
  );
}
