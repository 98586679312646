import { useEffect, useState } from "react";
import "../../index.css";
import AddIcon from "../asset/icon/add";
import ConnectWallet from "../connectwallet";
import Controller from "../controller";
import Button from "../button";
import RepeatIcon from "../asset/icon/repeat";
import WinnerIcon from "../asset/icon/winericon";
import WinerPage from "../winerpage";
import JetIcon from "../asset/icon/jet";
import axios from "axios";
import { ProjectApi } from "../../api/Api";
import DisableIcon from "../asset/icon/disable";
import {useStore} from "../../store";
import Loading from "../loading";
import { useTonWallet } from "@tonconnect/ui-react";

const color = [
    {
      text: "SUBMIT",
      icon: <JetIcon />,
      className: "text-[#3BFFFF] border-[#3BFFFF] shadow-[0px_0px_8px_0px_rgba(59,255,255,1.00)]",
    },
    {
      text: "CHANGE",
      icon: <RepeatIcon />,
      className: "text-[#cafd7b] border-[#cafd7b] shadow-[0px_0px_8px_0px_rgba(202,253,123,1)]",
    },
    {
      text: "SUBMIT",
      icon: <DisableIcon />,
      className: "bg-[#454545]/60 shadow-[0px_0px_4px_0px_rgba(0,240,255,0.13)] border-[#454545] text-neutral-50/10",
    },
  ];
  
  export default function Home() {
  const [controller, setcontroller] = useState('disable');
  const [openModal, setopenModal] = useState(false);
  const [time , settime]=useState({});
  const [finish,setfinish]=useState('')
  const [values , setvalues]=useState({
    number1 : 1,
    number2 : 1
  })
  const [countdown , setcountdown]=useState('');
  const [lastPredict , setlastPredict]=useState({
    diceNumber1 : null,
    diceNumber2 : null
  });
  const [loading , setloading]=useState(true)
  const {player_id,setPlayer_id} = useStore()
  const wallet = useTonWallet();

  const telegram =async()=>{
    try{
      const tg = window.Telegram.WebApp.initDataUnsafe.user;
      const response = await axios.post(ProjectApi[1].postAuth  , { telegram_data: tg });
      setPlayer_id(`token ${response.data.player_id}`)
      setloading(false)
    }catch(err){
      console.log(err)
      setloading(false)
    }
  }

  const modalHandler = () => {
      openModal ? setopenModal(false) : setopenModal(true);
  };

  const BTNhandler =async() =>{
      try{
        const response =await axios.get(ProjectApi[2].predict,{
          headers:{
            Authorization: player_id
          }
        })
        const number1 = response.data.dice_number1
        // get last predict 
        if(response.data.dice_number1 !== null && response.data.dice_number2 !== null){
          setlastPredict({
            diceNumber1 : response.data.dice_number1,
            diceNumber2 : response.data.dice_number2
          })
        }else{
          setlastPredict({
            diceNumber1 : 1,
            diceNumber2 : 1
          })
        }
  
        console.log({finish, wallet, number1});
        
          if(finish || wallet?.account?.address == null){
            setcontroller('disable');
            console.log('disable')
          }else if(!finish && wallet?.account?.address != null && number1 === null){
            setcontroller('submit');
            console.log('submit')
          }else if(!finish && wallet?.account?.address != null && number1 !== null){
            setcontroller('change');
            console.log('change')
          }
      }catch(err){
        console.log(err.message)
        setloading(false)
      }
  }
  
  const timeHandler = async () => {
    try {
      const response = await axios.get(ProjectApi[0].countDown);
      const date = new Date(response.data.expire_dt);
      setcountdown(getFormattedDate(date))
      setfinish(response.data.is_finished);
      startCountdown()
      const calculateRemainingTime = () => {
        const now = new Date();
        const diff = date - now; 
  
        if (diff <= 0) {
            axios.get(ProjectApi[0].endEvent);
            return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }
  
        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);
  
        return { days, hours, minutes, seconds };
      };
  
      const remainingTime = calculateRemainingTime();
      settime(remainingTime) 
    } catch (error) {
      console.error(error);
      return { days: 0, hours: 0, minutes: 0, seconds: 0 }; 
    }
  }; 

  function getFormattedDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear(); 

    return `${year}/${month}/${day}`;
}

  const startCountdown = () => {
    const interval = setInterval(() => {
        settime((prevTime) => {
        let { days, hours, minutes, seconds } = prevTime;

        if (
          days === 0 &&
          hours === 0 &&
          minutes === 0 &&
          seconds === 0
        ) {
          clearInterval(interval);
          return prevTime; 
        }

       
        if (seconds > 0) {
          seconds -= 1;
        } else if (minutes > 0) {
          seconds = 59;
          minutes -= 1;
        } else if (hours > 0) {
          seconds = 59;
          minutes = 59;
          hours -= 1;
        } else if (days > 0) {
          seconds = 59;
          minutes = 59;
          hours = 23;
          days -= 1;
        }

        return { days, hours, minutes, seconds };
      });
    }, 1000);
  };

  const formatNumber = (num) => (num < 10 ? `0${num}` : num);

  useEffect(()=>{
    telegram().then(()=> {
      if(player_id) BTNhandler()
    });
  },[ player_id , wallet])

    useEffect(() => {
      timeHandler();
    },[]);

    useEffect(()=>{
      BTNhandler();
    },[wallet ,finish])

  return (
    <div>
      {
        loading ? <Loading /> :
      <div className="home-layout">
        {openModal ? (
          <WinerPage modalHandler={modalHandler} countdown={countdown} />
        ) : (
          <div>
            <div className="text-center text-white text-xl font-medium] my-7">
              <p>Predict the Dice, <br/> <span className="text-[#00efff]">$100</span> for the Prize!
              Cash in Your Wallet</p>
            </div>
            <div className="w-[170px] mx-auto">
              <ConnectWallet auth={player_id}/>
            </div>
            <div className="my-8">
              <div className="w-[330px] h-[80px] flex justify-between items-center px-3 mx-auto rounded-[15px] shadow-[inset_0px_4px_26.600000381469727px_-7px_rgba(26,229,161,1.00)] border border-[#1ae5a1] ">
                <div className="mx-1">
                  <AddIcon />
                </div>
                <a href="https://t.me/dicemaniacs">
                  <p className="text-[#1ae5a1] text-[14px] font-semibold px-1">
                    JOIN DICE MANIACS CHANNEL TO WATCH LATEST COMBINATIONS
                  </p>
                </a>
              </div>
              <div className="text-center mt-2">
                <a href="https://t.me/dicemaniacs">
                  <button className="w-[330px] text-[#1ae5a1] h-10 shadow-[inset_0px_4px_26.600000381469727px_-7px_rgba(26,229,161,1.00)]  rounded-lg border border-[#1ae5a1] justify-center items-center inline-flex text-[19px] font-medium">
                    JOIN NOW
                  </button>
                </a>
              </div>
            </div>
            <div className="text-center mb-8">
              <p className="text-white text-xs font-medium">
                NEXT DICE COMBINATION WILL REVEAL IN :
              </p>
            </div>
            <div className="flex justify-evenly items-center">
              <div className="text-center">
                <p className="text-[#3bffff] text-xs font-medium">DAYS</p>
                <p className="text-white text-2xl font-medium">{formatNumber(time.days)}</p>
              </div>
              <div className="text-center">
                <p className="text-[#3bffff] text-xs font-medium">HOURS</p>
                <p className="text-white text-2xl font-medium">{formatNumber(time.hours)}</p>
              </div>
              <div className="text-center">
                <p className="text-[#3bffff] text-xs font-medium">MINUTES</p>
                <p className="text-white text-2xl font-medium">{formatNumber(time.minutes)}</p>
              </div>
              <div className="text-center">
                <p className="text-[#3bffff] text-xs font-medium">SECONDS</p>
                <p className="text-white text-2xl font-medium">{formatNumber(time.seconds)}</p>
              </div>
            </div>
            <div className="my-9">
              <Controller lastPredict={lastPredict} setlastPredict={setlastPredict} values={values} setvalues={setvalues}/>
            </div>
            <div className="flex justify-center relative h-20">  
              {controller === 'disable' && <Button auth={player_id} {...color[2]} controller={controller} values={values}/>} 
              {controller === 'submit' && <Button auth={player_id} {...color[0]} controller={controller} values={values}/>}
              {controller === 'change' && <Button auth={player_id} {...color[1]} controller={controller} values={values}/>}
              <div onClick={modalHandler} className=" absolute right-[10px]">
                <WinnerIcon />
              </div>
            </div>
          </div>
        )}
      </div>
    }
    </div>
  );
}
